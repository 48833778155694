window.sliderTimer9 = function sliderTimer9() {
    this.setInterval(changeachievementmobauto, 5000);
}
window.addEventListener('load', () => {
    sliderTimer9();
})

window.changeachievementmobauto = function changeachievementmobauto() {

    var firstdiv = document.getElementById("swipeChoosethreemob").firstElementChild;
    var lastdiv = document.getElementById("swipeChoosethreemob").lastElementChild;
    var listtest = document.getElementById("swipeChoosethreemob"); // Get the <ul> element to insert a new node
    lastdiv.insertAdjacentElement("afterend", firstdiv);
    var idawards = listtest.children[0].id;

    var isPaused = false;
    if (!window.isPaused) {
        if (idawards === "mobachievement1") {
            document.getElementById('mobachievement1').style.display = 'flex';
            document.getElementById('mobachievement2').style.display = 'none';
            document.getElementById('mobachievement3').style.display = 'none';
            document.getElementById('mobachievement4').style.display = 'none';


            document.getElementById('dotachievementmob1').classList.add('dotacheivementmob');
            document.getElementById('dotachievementmob2').classList.remove('dotacheivementmob');
            document.getElementById('dotachievementmob3').classList.remove('dotacheivementmob');
            document.getElementById('dotachievementmob4').classList.remove('dotacheivementmob');


        } else if (idawards === "mobachievement2") {
            document.getElementById('mobachievement1').style.display = 'none';
            document.getElementById('mobachievement2').style.display = 'flex';
            document.getElementById('mobachievement3').style.display = 'none';
            document.getElementById('mobachievement4').style.display = 'none';

            document.getElementById('dotachievementmob1').classList.remove('dotacheivementmob');
            document.getElementById('dotachievementmob2').classList.add('dotacheivementmob');
            document.getElementById('dotachievementmob3').classList.remove('dotacheivementmob');
            document.getElementById('dotachievementmob4').classList.remove('dotacheivementmob');



        } else if (idawards === "mobachievement3") {
            document.getElementById('mobachievement1').style.display = 'none';
            document.getElementById('mobachievement2').style.display = 'none';
            document.getElementById('mobachievement3').style.display = 'flex';
            document.getElementById('mobachievement4').style.display = 'none';


            document.getElementById('dotachievementmob1').classList.remove('dotacheivementmob');
            document.getElementById('dotachievementmob2').classList.remove('dotacheivementmob');
            document.getElementById('dotachievementmob3').classList.add('dotacheivementmob');
            document.getElementById('dotachievementmob4').classList.remove('dotacheivementmob');


        } else if (idawards === "mobachievement4") {
            document.getElementById('mobachievement1').style.display = 'none';
            document.getElementById('mobachievement2').style.display = 'none';
            document.getElementById('mobachievement3').style.display = 'none';
            document.getElementById('mobachievement4').style.display = 'flex';


            document.getElementById('dotachievementmob1').classList.remove('dotacheivementmob');
            document.getElementById('dotachievementmob2').classList.remove('dotacheivementmob');
            document.getElementById('dotachievementmob3').classList.remove('dotacheivementmob');
            document.getElementById('dotachievementmob4').classList.add('dotacheivementmob');


        }
    }
}

window.changeachievementmob = function changeachievementmob(id) {

    var isPaused = false;

    if (id === 'dotachievementmob1') {
        document.getElementById('mobachievement1').style.display = 'flex';
        document.getElementById('mobachievement2').style.display = 'none';
        document.getElementById('mobachievement3').style.display = 'none';
        document.getElementById('mobachievement4').style.display = 'none';


        document.getElementById('dotachievementmob1').classList.add('dotacheivementmob');
        document.getElementById('dotachievementmob2').classList.remove('dotacheivementmob');
        document.getElementById('dotachievementmob3').classList.remove('dotacheivementmob');
        document.getElementById('dotachievementmob4').classList.remove('dotacheivementmob');


    } else if (id === 'dotachievementmob2') {
        document.getElementById('mobachievement1').style.display = 'none';
        document.getElementById('mobachievement2').style.display = 'flex';
        document.getElementById('mobachievement3').style.display = 'none';
        document.getElementById('mobachievement4').style.display = 'none';

        document.getElementById('dotachievementmob1').classList.remove('dotacheivementmob');
        document.getElementById('dotachievementmob2').classList.add('dotacheivementmob');
        document.getElementById('dotachievementmob3').classList.remove('dotacheivementmob');
        document.getElementById('dotachievementmob4').classList.remove('dotacheivementmob');



    } else if (id === 'dotachievementmob3') {
        document.getElementById('mobachievement1').style.display = 'none';
        document.getElementById('mobachievement2').style.display = 'none';
        document.getElementById('mobachievement3').style.display = 'flex';
        document.getElementById('mobachievement4').style.display = 'none';


        document.getElementById('dotachievementmob1').classList.remove('dotacheivementmob');
        document.getElementById('dotachievementmob2').classList.remove('dotacheivementmob');
        document.getElementById('dotachievementmob3').classList.add('dotacheivementmob');
        document.getElementById('dotachievementmob4').classList.remove('dotacheivementmob');


    } else if (id === 'dotachievementmob4') {
        document.getElementById('mobachievement1').style.display = 'none';
        document.getElementById('mobachievement2').style.display = 'none';
        document.getElementById('mobachievement3').style.display = 'none';
        document.getElementById('mobachievement4').style.display = 'flex';


        document.getElementById('dotachievementmob1').classList.remove('dotacheivementmob');
        document.getElementById('dotachievementmob2').classList.remove('dotacheivementmob');
        document.getElementById('dotachievementmob3').classList.remove('dotacheivementmob');
        document.getElementById('dotachievementmob4').classList.add('dotacheivementmob');


    }

}


var mySwipecreator = document.getElementById('swipeChoosethreemob');
if (mySwipecreator) {
    var mySwipecreator = new Hammer(mySwipecreator, {
        touchAction: "pan-y"
    });

    mySwipecreator.get('pan').set({
        direction: Hammer.DIRECTION_ALL
    });

    var idachievement = '';


    mySwipecreator.on("swipeleft swiperight", function (ev) {
        var firstBlockC = document.getElementById('mobachievement1');
        var secondBlockC = document.getElementById('mobachievement2');
        var thirdBlockC = document.getElementById('mobachievement3');
        var fourthBlockC = document.getElementById('mobachievement4');

        var oneBlockC = document.getElementById('dotachievementmob1');
        var twoBlockC = document.getElementById('dotachievementmob2');
        var threeBlockC = document.getElementById('dotachievementmob3');
        var fourBlockC = document.getElementById('dotachievementmob4');

        // console.log("HERE :: ",ev)
        console.log("HERE :: ", ev.type)
        elementt = document.getElementsByClassName("dotacheivementmob")[0];
        idachievement = elementt.id;
        console.log("My iDD :: ", idachievement)



        if (ev.type === 'swipeleft') {

            if (idachievement === 'dotachievementmob1') {

                firstBlockC.style.display = 'none';
                secondBlockC.style.display = 'flex';
                thirdBlockC.style.display = 'none';
                fourthBlockC.style.display = 'none';

                oneBlockC.classList.remove('dotacheivementmob');
                twoBlockC.classList.add('dotacheivementmob');
                threeBlockC.classList.remove('dotacheivementmob');
                fourBlockC.classList.remove('dotacheivementmob');



            } else if (idachievement === 'dotachievementmob2') {
                firstBlockC.style.display = 'none';
                secondBlockC.style.display = 'none';
                thirdBlockC.style.display = 'flex';
                fourthBlockC.style.display = 'none';

                oneBlockC.classList.remove('dotacheivementmob');
                twoBlockC.classList.remove('dotacheivementmob');
                threeBlockC.classList.add('dotacheivementmob');
                fourBlockC.classList.remove('dotacheivementmob');


            } else if (idachievement === 'dotachievementmob3') {
                firstBlockC.style.display = 'none';
                secondBlockC.style.display = 'none';
                thirdBlockC.style.display = 'none';
                fourthBlockC.style.display = 'flex';

                oneBlockC.classList.remove('dotacheivementmob');
                twoBlockC.classList.remove('dotacheivementmob');
                threeBlockC.classList.remove('dotacheivementmob');
                fourBlockC.classList.add('dotacheivementmob');



            } else if (idachievement === 'dotachievementmob4') {
                firstBlockC.style.display = 'flex';
                secondBlockC.style.display = 'none';
                thirdBlockC.style.display = 'none';
                fourthBlockC.style.display = 'none';

                oneBlockC.classList.add('dotacheivementmob');
                twoBlockC.classList.remove('dotacheivementmob');
                threeBlockC.classList.remove('dotacheivementmob');
                fourBlockC.classList.remove('dotacheivementmob');



            }

        }

        if (ev.type === 'swiperight') {
            if (idachievement === 'dotachievementmob1') {
                firstBlockC.style.display = 'none';
                secondBlockC.style.display = 'none';
                thirdBlockC.style.display = 'none';
                fourthBlockC.style.display = 'flex';

                oneBlockC.classList.remove('dotacheivementmob');
                twoBlockC.classList.remove('dotacheivementmob');
                threeBlockC.classList.remove('dotacheivementmob');
                fourBlockC.classList.add('dotacheivementmob');


            } else if (idachievement === 'dotachievementmob2') {
                firstBlockC.style.display = 'flex';
                secondBlockC.style.display = 'none';
                thirdBlockC.style.display = 'none';
                fourthBlockC.style.display = 'none';

                oneBlockC.classList.add('dotacheivementmob');
                twoBlockC.classList.remove('dotacheivementmob');
                threeBlockC.classList.remove('dotacheivementmob');
                fourBlockC.classList.remove('dotacheivementmob');


            } else if (idachievement === 'dotachievementmob3') {
                firstBlockC.style.display = 'none';
                secondBlockC.style.display = 'flex';
                thirdBlockC.style.display = 'none';
                fourthBlockC.style.display = 'none';

                oneBlockC.classList.remove('dotacheivementmob');
                twoBlockC.classList.add('dotacheivementmob');
                threeBlockC.classList.remove('dotacheivementmob');
                fourBlockC.classList.remove('dotacheivementmob');


            } else if (idachievement === 'dotachievementmob4') {
                firstBlockC.style.display = 'none';
                secondBlockC.style.display = 'none';
                thirdBlockC.style.display = 'flex';
                fourthBlockC.style.display = 'none';

                oneBlockC.classList.remove('dotacheivementmob');
                twoBlockC.classList.remove('dotacheivementmob');
                threeBlockC.classList.add('dotacheivementmob');
                fourBlockC.classList.remove('dotacheivementmob');


            }
        }

    });
}